
export const serverAppConfig = {
  "storeType": "B2C",
  "storeDeliveries": [
    {
      "id": "64a29467ce99623cb8967c90",
      "name": " ",
      "label": " ",
      "buyable": true
    }
  ],
  "language": "sv",
  "paths": {
    "brandsUrlPath": "/varumarken",
    "categoriesUrlPath": "/categories",
    "tagsUrlPath": "/t",
    "productsUrlPath": "/p",
    "blogsUrlPath": "/blogg"
  },
  "staticLinks": [
    {
      "id": "tvattstuga",
      "label": "Tvättstuga",
      "path": "/tvattstuga"
    },
    {
      "id": "professionelldisk",
      "label": "Professionell disk",
      "path": "/professionell-disk"
    },
    {
      "id": "service",
      "label": "Service & Installation",
      "path": "/service"
    },
    {
      "id": "omoss",
      "label": "Om oss",
      "path": "/om-oss"
    },
    {
      "id": "blog",
      "label": "Blogg",
      "path": "/blogg"
    }
  ],
  "logoUrl": "https://storage.googleapis.com/gecko-media/EC_1035/Stenlunds_prof_logo_tagline.svg",
  "showCart": false,
  "sortingOptions": [
    {
      "sortBy": "price",
      "sortOrder": "DESC",
      "sortLabel": "Pris högt-lågt",
      "default": false
    },
    {
      "sortBy": "price",
      "sortOrder": "ASC",
      "sortLabel": "Pris lågt-högt",
      "default": true
    },
    {
      "sortBy": "created",
      "sortOrder": "DESC",
      "sortLabel": "Nyaste",
      "default": false
    }
  ],
  "desktopItemHeight": "auto",
  "mobileItemHeight": "auto",
  "sliderDefaultValues": [
    0,
    350000
  ],
  "freeShippingOver": 499,
  "shippingProviderConfig": {
    "provider": "DEFAULT",
    "simpleShipping": {
      "minimumOrderAmountForFreeDelivery": 499,
      "deliveryFee": 139
    }
  },
  "paymentProviderConfig": {
    "providerName": "KLARNA",
    "providerOptions": {}
  },
  "filtersBeforeShowMore": 3,
  "sliderMaxPrice": 350000,
  "topNavHeight": {
    "panel": {
      "base": "5vh",
      "lg": 0
    },
    "content": {
      "base": "5vh",
      "lg": "16vh"
    },
    "spacer": {
      "base": "12vh",
      "lg": "16vh"
    },
    "bar": {
      "base": "7vh",
      "lg": "10vh"
    },
    "menu": {
      "base": "0",
      "lg": "6vh"
    }
  },
  "topNavSlideOutFeaturedCategories": 3,
  "socialMediaItems": [],
  "topNavPanel": {
    "items": [
      {
        "label": "Boka våra tjänster",
        "href": "/boka-service"
      }
    ]
  }
};
export const serverTheme = {}; 
